<template>
    <div class="h5-WeChat">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'h5-WeChat',
        mounted () {
            this.$nextTick(() => {
                this.timer = setInterval(() => {
                    let dom = document.getElementById('chatBtn')
                    if (dom) {
                        dom.setAttribute('class', 'hide')
                        clearInterval(this.timer)
                        this.timer = null
                    }
                }, 200)
            })
        },
        destroyed () {
            clearInterval(this.timer)
            this.timer = null
        }
    }
</script>

<style scoped>

</style>